<template>
  <v-dialog v-model="showModal" max-width="500px" persistent>
    <v-card>
      <!-- <v-card-title class="headline my-2">We have prepared new functionalities for you!</v-card-title> -->
      <v-card-text style="padding: 0!important;">
        <v-stepper v-model="currentStep">
          <!-- Klikabilni koraci -->
          <v-stepper-header>
            <!-- Step 1 -->
            <v-stepper-step
              :complete="currentStep > 1"
              step="1"
              color="#6c62ef"
              @click="currentStep = 1"
            >
              Step 1
            </v-stepper-step>
            <v-divider></v-divider>
            <!-- Step 2 -->
            <v-stepper-step
              :complete="currentStep > 2"
              step="2"
              color="#6c62ef"
              @click="currentStep = 2"
            >
              Step 2
            </v-stepper-step>
            <v-divider></v-divider>
            <!-- Step 3 -->
            <v-stepper-step
              step="3"
              color="#6c62ef"
              @click="currentStep = 3"
            >
              Step 3
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- Step 1 Content -->
            <v-stepper-content step="1">
              <h5><b>Introducing Text-to-Speech in LitteraWorks</b></h5>
              <p class="text-welcome">
                We are launching a new feature: Text-to-Speech.
              </p>
              <p class="text-welcome mb-4">
                Transform your written text into AI-generated speech in multiple languages, perfect for media, content creation, or accessibility needs.
              </p>
              <img class="show" src="https://app-works.app/image/cache/large/files/images/screenshot-2024-12-19-120659.png?crop=true">
              <v-btn color="primary" @click="nextStep" class="mt-5">Next</v-btn>
            </v-stepper-content>

            <!-- Step 2 Content -->
            <v-stepper-content step="2">
              <h5><b>Try It for Free</b></h5>
              <p>Want to hear how it works?</p>
              <ul class="text-welcome">
                <li class="text-welcome" >
                  <router-link :to="{ name: 'textToSpeech' }">Go to the Text-to-Speech section.</router-link>
                </li>
                <li class="text-welcome">Select a language and voice to preview the feature.</li>
                <li class="text-welcome">Contact us to activate your free trial and experience it with your text!</li>
              </ul>
              <img class="show" src="https://app-works.app/image/cache/large/files/images/screenshot-2024-12-19-121159.png?crop=true">
              <v-btn color="primary" @click="nextStep" class="mt-5">Next</v-btn>
            </v-stepper-content>

            <!-- Step 3 Content -->
            <v-stepper-content step="3">
              <h5><b>Simple and Transparent Pricing</b></h5>
              <p>After free trial, you can use Text-to-Speech on a per-usage basis:</p>
              <ul>
                <li class="text-welcome">Pay only for what you generate—no hidden fees.</li>
                <li class="text-welcome">Easily track your usage and costs in a new dashboard.</li>
                <li class="text-welcome">If you would like to use it at scale, we can connect to your API.</li>
              </ul>
              <p><b>Contact us to activate Text-to-Speech now!</b></p>
              <img class="show" src="https://app-works.app/image/cache/large/files/images/screenshot-2024-12-19-122844.png?crop=true">

              <v-btn color="primary" @click="closeModal" class="mt-5">Close</v-btn>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      showModal: false, // Kontroliše prikaz modala
      currentStep: 1, // Trenutni korak u stepperu
    };
  },
  mounted() {
    const hasSeenModal = localStorage.getItem("hasSeenWelcomeModal");
    if (!hasSeenModal) {
      this.showModal = true;
    }
  },
  methods: {
    closeModal() {
      this.showModal = false;
      localStorage.setItem("hasSeenWelcomeModal", true); // Postavi lokalno skladište
    },
    nextStep() {
      if (this.currentStep < 3) {
        this.currentStep += 1; // Prelaz na sledeći korak
      }
    },
  },
};
</script>
<style>
.v-stepper__header {
    box-shadow: 0 0px 10px 0px rgba(0, 0, 0, .2)!important;
}
.show {
    width: 100%!important;
    margin: 0px 0 20px 0;
}
.text-welcome {
  font-weight: 400!important;
  color:#4e4e4e;
}
.height-1 {
  height: 324px;
}
</style>
